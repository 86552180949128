import { CardProps, Service, Services } from "@/types"
import { getDurationText } from "@/utils/catalogue"

export type SortFn = (services: Service[]) => Service[]

export const sortByCreatedAtDesc: SortFn = (services: Service[]): Service[] =>
	[...services].sort((a, b) => {
		if (!a.createdAt || !b.createdAt) {
			return 0
		}

		return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
	})

export type FilterFn = (services: Service[]) => Service[]

export const getFilteredServices = (services: Service[], filters: FilterFn[]): Service[] =>
	filters.reduce((filteredServices, filter) => filter(filteredServices), services)

export const mapServicesToCard = (services: Services): CardProps[] => {
	const formatCaption = (service: Service) => {
		if (service.duration === 0) {
			return ""
		}

		return getDurationText(service.duration)
	}

	return services.map((service) => ({
		id: service.catalogueItemID,
		name: service.name,
		description: service.description,
		category: service.categories[0],
		caption: formatCaption(service),
		price: service.price,
		variants: [],
		imageUrls: service.imageList.raw ?? [],
		createdAt: service.createdAt,
		type: "service",
		isVatApplicable: service.isVatApplicable,
	}))
}
