import { TableColumns } from "@/components/catalogue/table/CatalogueTable"
import { RenderPrice, RenderThumbnail } from "@/components/catalogue/table/CatalogueTableCell"

/**
 * Columns for the products CatalogueTable.
 */
export const productColumns: TableColumns = [
	{
		id: "name",
		align: "left",
		padding: "normal",
		label: "Product name",
		width: 320,
		render: RenderThumbnail,
	},
	{
		id: "category",
		align: "left",
		padding: "normal",
		width: 320,
		label: "Category",
	},
	{
		id: "price",
		align: "left",
		padding: "normal",
		label: "Price",
		width: 320,
		render: RenderPrice,
	},
]
