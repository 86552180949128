import Checkbox from "@/components/catalogue/table/CatalogueTable/Checkbox"
import { TableColumns } from "@/components/catalogue/table/CatalogueTable"
import { CatalogueTableState } from "@/state"
import { styled } from "@mui/material/styles"
import MUITableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import React from "react"

const TableCell = styled(MUITableCell)(({ theme }) => ({
	"&.MuiTableCell-root": {
		borderBottom: `1px solid ${theme.palette.divider}`,
	},
}))

type TableHeaderProps = Pick<CatalogueTableState, "totalItemCount" | "checked"> & {
	columns: TableColumns
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void
}
const TableHeader = (props: TableHeaderProps) => {
	const { totalItemCount, columns, checked, onSelectAllClick } = props

	const checkedCount = checked.length

	return (
		<TableHead>
			<TableRow>
				<TableCell padding="checkbox">
					<Checkbox
						indeterminate={checkedCount > 0 && checkedCount < totalItemCount}
						checked={totalItemCount > 0 && checkedCount === totalItemCount}
						onChange={onSelectAllClick}
						inputProps={{
							"aria-label": "select all items",
						}}
					/>
				</TableCell>
				{columns.map((column) => (
					<TableCell width={column.width} key={column.id} align={column.align} padding={column.padding}>
						{column.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	)
}

export default TableHeader
