import { TableColumns } from "@/components/catalogue/table/CatalogueTable"
import { RenderDuration, RenderPrice, RenderThumbnail } from "@/components/catalogue/table/CatalogueTableCell"

/**
 * Columns for the services CatalogueTable.
 */
export const servicesColumns: TableColumns = [
	{
		id: "name",
		align: "left",
		padding: "normal",
		label: "Service name",
		width: 350,
		render: RenderThumbnail,
	},
	{
		id: "category",
		align: "left",
		padding: "normal",
		width: 350,
		label: "Category",
	},
	{
		id: "duration",
		align: "left",
		padding: "normal",
		label: "Duration",
		width: 180,
		render: RenderDuration,
	},
	{
		id: "price",
		align: "left",
		padding: "normal",
		label: "Price",
		width: 180,
		render: RenderPrice,
	},
]
