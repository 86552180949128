import { getRandCurrencyText } from "@/utils"
import Typography from "@mui/material/Typography"
import React from "react"

type RenderPriceProps = {
	price: number
	subItems?: Array<{ price: number }>
}
/**
 * Render price for Catalogue Table
 */
export const RenderPrice = ({ price, subItems }: RenderPriceProps) => (
	<Typography variant="body2">
		{!subItems || subItems?.length === 0
			? getRandCurrencyText(price)
			: `from ${getRandCurrencyText(Math.min(...subItems.map((item) => item.price)))}`}
	</Typography>
)
