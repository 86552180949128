import { TableRows } from "@/components/catalogue/table/CatalogueTable"
import { Product } from "@/types"

/**
 * Creates table rows, from products, with the correct fields.
 * @param products
 * @returns
 */
export const createTableRows = (products: Product[]): TableRows => {
	return products.map((product) => ({
		id: product.catalogueItemID,
		name: product.name,
		category: product.categories[0],
		price: product.price,
		imagePath: product.imageList.thumbnail?.[0],
		subItems: product.variants?.map((variant) => {
			return {
				id: `${variant.catalogueItemID}.${variant.variantID}`,
				name: Object.values(variant.variantValues)[0],
				price: variant.price,
			}
		}),
	}))
}
