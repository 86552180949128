import { TableRows } from "@/components/catalogue/table/CatalogueTable"
import TableRow from "@/components/catalogue/table/CatalogueTable/TableRow"
import Checkbox from "@/components/catalogue/table/CatalogueTable/Checkbox"
import { RenderPrice, RenderThumbnail } from "@/components/catalogue/table/CatalogueTableCell"
import { getRandCurrencyText } from "@/utils"
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown"
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight"
import { styled, useTheme } from "@mui/material"
import Collapse from "@mui/material/Collapse"
import IconButton from "@mui/material/IconButton"
import { alpha } from "@mui/material/styles"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import React from "react"

const GroupedCell = styled(TableCell)(({ theme }) => ({
	borderBottom: alpha(theme.palette.common.black, 0.12),
	cursor: "pointer",
}))

const StyledCell = styled(TableCell)(() => ({
	borderBottom: "unset",
}))

export type TableCollapseProps = {
	item: TableRows[0]
	checked: string[]
	handleSelect: (value: string) => () => void
}
const TableCollapse = ({ item, checked, handleSelect }: TableCollapseProps) => {
	const [open, setOpen] = React.useState(false)
	const selected = checked.some((c) => c.includes(item.id))

	const theme = useTheme()

	return (
		<>
			<TableRow hover={!selected} onClick={() => setOpen(!open)}>
				<StyledCell padding="checkbox">
					<IconButton aria-label="expand row" sx={{ size: 0 }}>
						{open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
					</IconButton>
				</StyledCell>
				<StyledCell component="th" scope="row" size="small">
					<RenderThumbnail
						name={item.name}
						selected={selected}
						variantsCount={item.subItems?.length}
						imagePath={item.imagePath}
					/>
				</StyledCell>
				<StyledCell>{item.category}</StyledCell>
				<StyledCell>
					<RenderPrice price={item.price} subItems={item.subItems} />
				</StyledCell>
			</TableRow>
			<TableRow>
				<TableCell padding="none" colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Table size="medium" aria-label="grouped rows">
							<TableBody sx={{ background: theme.palette.grey[50] }}>
								{item.subItems?.map(({ id, name, price }: { id: string; name: string; price: number }) => (
									<TableRow key={id} onClick={handleSelect(id)}>
										<GroupedCell padding="checkbox" sx={{ pl: "1.75rem" }}>
											<Checkbox
												checked={checked.some((c) => c.includes(id))}
												inputProps={{ "aria-labelledby": `checkbox-collapse-label-${id}` }}
											/>
										</GroupedCell>
										<GroupedCell width={300} component="th" scope="row">
											{name}
										</GroupedCell>
										<GroupedCell width={300}>{item.category}</GroupedCell>
										<GroupedCell width={300}>{getRandCurrencyText(price)}</GroupedCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	)
}

export default TableCollapse
