import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"

type NoRowsProps = {
	icon: React.ReactElement | null
	description: string
}

const NoRows = ({ icon, description, children }: React.PropsWithChildren<NoRowsProps>) => (
	<Stack height="32rem" direction="column" alignItems="center" justifyContent="center">
		{icon}
		<Typography variant="body1" color="teal" padding="0.75rem">
			{description}
		</Typography>
		{children}
	</Stack>
)

export default NoRows
