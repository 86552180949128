import { TypographyProps } from "@mui/material"
import Avatar from "@mui/material/Avatar"
import Typography from "@mui/material/Typography"
import React from "react"

type CatalogueItemThumbnailProps = {
	name: string
	imageUrl?: string
	thumbnailTextTransformer?: (name: string) => string
	/**
	 * If specified, will override the default typography props used to display the thumbnail text.
	 */
	typographyProps?: TypographyProps
}

const defaultThumbnailTextTransformer = (name: string) => name.toString().slice(0, 2).toLowerCase()

const defaultTypographyProps: TypographyProps = {
	textTransform: "capitalize",
	variant: "subtitle1",
	fontSize: "1.25rem",
}

/**
 * Displays the thumbnail of a catalogue item.
 * If no img url is provided, it will display the first 2 letters of the name.
 */
const CatalogueItemThumbnail = ({
	name,
	imageUrl,
	thumbnailTextTransformer = defaultThumbnailTextTransformer,
	typographyProps = defaultTypographyProps,
}: CatalogueItemThumbnailProps) => {
	if (imageUrl) {
		return <Avatar src={imageUrl} alt={name} />
	}

	return (
		<Avatar>
			<Typography {...typographyProps}>{thumbnailTextTransformer(name)}</Typography>
		</Avatar>
	)
}

export default CatalogueItemThumbnail
