import ErrorModal from "@/components/ErrorModal"
import Loader from "@/components/Loader"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import React from "react"

type FetchCatalogueProps = {
	isLoading: boolean
	isError: boolean
	retry: () => void
}

const FetchCatalogue = ({ children, isLoading, isError, retry }: React.PropsWithChildren<FetchCatalogueProps>) => {
	if (isLoading) {
		return (
			<Stack height="32rem" justifyContent="center" alignItems="center">
				<Loader />
			</Stack>
		)
	}

	if (isError) {
		return (
			<Stack height="32rem" justifyContent="center" alignItems="center">
				<ErrorModal
					header="Server Error"
					description={
						<Typography>
							We couldn't reach the iKhokha server. Please try again or contact ikhokha Support Team on{" "}
							<strong>087 222 7000</strong> or email us at <strong>support@ikhokha.com</strong>
						</Typography>
					}
					handlerText="Retry"
					handleRetry={retry}
					elevation={0}
				/>
			</Stack>
		)
	}

	return <>{children}</>
}

export default FetchCatalogue
