import FetchCatalogue from "@/components/catalogue/FetchCatalogue"
import { createTableRows } from "@/components/catalogue/ProductsList/createTableRows"
import { productColumns } from "@/components/catalogue/ProductsList/tableColumns"
import CatalogueTable from "@/components/catalogue/table/CatalogueTable"
import { SearchTextField } from "@/components/catalogue/table/SearchTextField"
import { useProducts } from "@/hooks"
import { itemSelectionCountAtom, productsCheckedAtom, productsSearchTermAtom } from "@/state"
import { getFilteredProducts, sortByCreatedAtDesc } from "@/utils/product"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue } from "jotai"
import React from "react"

type ProductsListProps = {
	createCatalogueItemNavigateComp: React.ReactNode
	noRowsComp: React.ReactNode
	addToStoreComp: React.ReactNode
}
const ProductsList = (props: ProductsListProps) => {
	const { products, isLoading, isError, retry } = useProducts()
	const filteredProducts = getFilteredProducts(products, [sortByCreatedAtDesc])

	const totalItemCount = filteredProducts.reduce((acc, curr) => {
		acc = curr.variants?.length ? acc + curr.variants.length : acc + 1
		return acc
	}, 0)

	const [searchTerm, setSearchTerm] = useAtom(productsSearchTermAtom)
	const [productsChecked, setProductsChecked] = useAtom(productsCheckedAtom)
	const itemSelectionCount = useAtomValue(itemSelectionCountAtom)

	const searchedProducts =
		searchTerm === ""
			? filteredProducts
			: filteredProducts.filter(({ name, categories, price }) =>
					(name + categories[0] + price).toLowerCase().includes(searchTerm.toLowerCase()),
				)

	const rows = React.useMemo(() => createTableRows(searchedProducts), [searchedProducts])

	return (
		<FetchCatalogue isLoading={isLoading} isError={isError} retry={retry}>
			{filteredProducts.length === 0 ? (
				props.noRowsComp
			) : (
				<>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						{props.createCatalogueItemNavigateComp}
						<SearchTextField placeholder="Search" valueState={[searchTerm, setSearchTerm]} />
					</Stack>
					<CatalogueTable
						columns={productColumns}
						rows={rows}
						totalItemCount={totalItemCount}
						checked={productsChecked}
						setChecked={setProductsChecked}
						searchTerm={searchTerm}
					/>
					<Stack direction="row-reverse" justifyContent="space-between" padding="0 1rem">
						<Typography variant="body2">
							Total products:{" "}
							<Typography component="span" variant="subtitle2">
								{filteredProducts.length}
							</Typography>
						</Typography>
						{!!itemSelectionCount && (
							<Typography variant="body2">
								<Typography component="span" variant="subtitle2">
									{itemSelectionCount}
								</Typography>{" "}
								items selected
							</Typography>
						)}
					</Stack>
				</>
			)}
			{props.addToStoreComp}
		</FetchCatalogue>
	)
}

export default ProductsList
