import NoProductsIcon from "@/assets/NoProductsYet"
import NoServicesIcon from "@/assets/NoServicesYet"
import CreateCatalogueRedirect from "@/components/catalogue/CreateCatalogueItemNavigation"
import IconButton from "@/components/catalogue/IconButton"
import NoRows from "@/components/catalogue/NoRows"
import ProductsList from "@/components/catalogue/ProductsList"
import ServicesList from "@/components/catalogue/ServicesList"
import TabPanel, { a11yProps } from "@/components/catalogue/TabPanel"
import ErrorModal from "@/components/ErrorModal/Common"
import Loader from "@/components/Loader"
import { useUpdateDraftWebstoreConfigMutation } from "@/hooks"
import { createCatalogueRedirectPending, productsCheckedAtom, selectedTabAtom, servicesCheckedAtom } from "@/state"
import { WebstoreConfig } from "@/types"
import { getCreateCatalogueItemUrl, isUnauthorizedError } from "@/utils"
import { datadogRum } from "@datadog/browser-rum"
import { alert, button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import CloseIcon from "@mui/icons-material/Close"
import LoadingButton from "@mui/lab/LoadingButton"
import { styled } from "@mui/material"
import Paper from "@mui/material/Paper"
import Stack from "@mui/material/Stack"
import Tab from "@mui/material/Tab"
import Tabs from "@mui/material/Tabs"
import Typography from "@mui/material/Typography"
import { useAtom } from "jotai"
import { useSearchParams } from "next/navigation"
import React from "react"

const StyledTab = styled(Tab)(() => ({
	height: "2.625rem",
	width: "11.25rem",
}))

type CatalogueSelectionProps = {
	owner: string
	webstoreName: string
	webstoreConfig: WebstoreConfig
	sitebuilder?: boolean
	onSave: () => void
}
const CatalogueSelection = (props: Readonly<CatalogueSelectionProps>) => {
	const searchParams = useSearchParams()

	const [productsChecked, setProductsChecked] = useAtom(productsCheckedAtom)
	const [servicesChecked, setServicesChecked] = useAtom(servicesCheckedAtom)

	const [selectedTab, setSelectedTab] = useAtom(selectedTabAtom)

	const [redirectPending, setRedirectPending] = useAtom(createCatalogueRedirectPending)

	React.useEffect(() => {
		const { catalogueItems } = props.webstoreConfig

		const checkedProducts = (catalogueItems?.productIds ?? []).flatMap((product) => {
			const { id, variantIds } = product
			if (variantIds.length === 0) {
				return id
			}
			return variantIds.map((id) => `${product.id}.${id}`)
		})

		const checkedServices = catalogueItems?.serviceIds ?? []

		setSelectedTab(Number(searchParams.get("tab")))
		setProductsChecked(checkedProducts)
		setServicesChecked(checkedServices)
	}, [props.webstoreConfig])

	const selectedItemCount = productsChecked.length + servicesChecked.length

	const {
		mutate: updateWebstoreConfig,
		error: updateWebstoreConfigError,
		isPending: updateWebstorePending,
		isSuccess: updateWebstoreSuccess,
		reset: resetUpdateWebstore,
	} = useUpdateDraftWebstoreConfigMutation()

	if (updateWebstoreSuccess) {
		props.onSave()
		resetUpdateWebstore()
	}

	const handleCreateCatalogueRedirect = () => {
		const type = selectedTab === 0 ? "product" : "service"
		window.location.href = `${getCreateCatalogueItemUrl(type)}?tab=${selectedTab}`
		return
	}

	const createProductComp = <CreateCatalogueRedirect catalogue="product" onNavigate={handleCreateCatalogueRedirect} />

	const createServiceComp = <CreateCatalogueRedirect catalogue="service" onNavigate={handleCreateCatalogueRedirect} />

	const saveButtonComp = (
		<LoadingButton
			onClick={() => updateWebstoreConfig()}
			disabled={selectedItemCount === 0 || !!updateWebstoreConfigError}
			loading={updateWebstorePending || updateWebstoreSuccess}
			size="large"
			variant="contained"
			sx={{
				textTransform: "none",
				width: "19.5rem",
			}}
		>
			{props.sitebuilder ? "Save" : "Save & continue"}
		</LoadingButton>
	)

	if (!!updateWebstoreConfigError && !isUnauthorizedError([updateWebstoreConfigError])) {
		datadogRum.addError(updateWebstoreConfigError)

		return (
			<Stack justifyContent={"center"} alignItems={"center"} paddingY={"5rem"}>
				<ErrorModal handleRetry={() => updateWebstoreConfig()} />
			</Stack>
		)
	}

	if (redirectPending) {
		const loader = (
			<Stack alignItems="center">
				<Loader text={`Hold on, we're redirecting you to add a ${selectedTab === 0 ? "product" : "service"}.`} />
			</Stack>
		)

		return <>{props.sitebuilder ? loader : <Paper sx={{ p: "2rem", my: "10rem" }}>{loader}</Paper>}</>
	}

	const catalogueSelection = (
		<Stack gap=".75rem">
			{props.sitebuilder ? (
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography sx={alert.title}>Manage your products and services</Typography>
					<IconButton aria-label="close" onClick={props.onSave}>
						<CloseIcon sx={{ color: "text.primary" }} />
					</IconButton>
				</Stack>
			) : (
				<Typography sx={alert.title}>Select Products and Services</Typography>
			)}
			<Stack direction="column" alignItems="center" sx={{ maxWidth: "inherit" }}>
				<Tabs
					sx={{
						borderBottom: 1,
						borderColor: "divider",
						...button.large,
					}}
					value={selectedTab}
					onChange={(_, updatedValue) => setSelectedTab(updatedValue)}
					textColor="secondary"
					aria-label="basic tabs example"
					variant="fullWidth"
				>
					<StyledTab label="Products" {...a11yProps(0)} />
					<StyledTab label="Services" {...a11yProps(1)} />
				</Tabs>
			</Stack>
			<Stack width="100%" sx={{ textAlign: "center" }}>
				<TabPanel value={selectedTab} index={0}>
					<ProductsList
						createCatalogueItemNavigateComp={createProductComp}
						noRowsComp={
							<NoRows icon={<NoProductsIcon />} description="No products added yet!">
								{createProductComp}
							</NoRows>
						}
						addToStoreComp={saveButtonComp}
					/>
				</TabPanel>
				<TabPanel value={selectedTab} index={1}>
					<ServicesList
						createCatalogueItemNavigateComp={createServiceComp}
						noRowsComp={
							<NoRows icon={<NoServicesIcon />} description="No services added yet!">
								{createServiceComp}
							</NoRows>
						}
						addToStoreComp={saveButtonComp}
					/>
				</TabPanel>
			</Stack>
		</Stack>
	)

	return (
		<>
			{props.sitebuilder ? (
				catalogueSelection
			) : (
				<Paper
					elevation={3}
					sx={{
						padding: "2rem 2.25rem",
						height: "40rem",
						width: "52rem",
					}}
				>
					{catalogueSelection}
				</Paper>
			)}
		</>
	)
}

export default CatalogueSelection
