import NoResults from "@/components/catalogue/NoResults"
import TableCollapse from "@/components/catalogue/table/CatalogueTable/TableCollapse"
import TableHeader from "@/components/catalogue/table/CatalogueTable/TableHeader"
import Checkbox from "@/components/catalogue/table/CatalogueTable/Checkbox"
import TableRow from "@/components/catalogue/table/CatalogueTable/TableRow"
import { CatalogueTableState } from "@/state"
import { useTheme } from "@mui/material"
import Stack from "@mui/material/Stack"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import React from "react"

export type TableColumns = Array<{
	id: string
	align: "left" | "right"
	padding: "normal" | "none"
	label: string
	width: number
	render?: (props: any) => React.ReactNode
}>

export type TableRows = Array<{
	id: string
	name: string
	category: string
	price: number
	imagePath?: string
	subItems?: Array<{
		id: string
		name: string
		price: number
	}>
}>

type CatalogueTableProps = CatalogueTableState & {
	columns: TableColumns
}

const CatalogueTable = (props: CatalogueTableProps) => {
	const theme = useTheme()

	const { columns, rows, totalItemCount, checked, setChecked, searchTerm } = props

	const handleSelect = (value: string) => () => {
		const currentIndex = checked.indexOf(value)
		const newChecked = [...checked]

		if (currentIndex === -1) {
			newChecked.push(value)
		} else {
			newChecked.splice(currentIndex, 1)
		}

		setChecked(newChecked)
	}

	const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = rows.flatMap((row) => {
				if (!row.subItems?.length) {
					return row.id
				}

				return row.subItems.reduce((acc: string[], curr: { id: string }) => {
					acc.push(curr.id)
					return acc
				}, [])
			})
			setChecked(newSelected)
			return
		}
		setChecked([])
	}

	return (
		<Stack height="24.5rem" padding="1rem 0">
			<TableContainer sx={{ borderBottom: `1px solid ${theme.palette.divider}` }}>
				<Table aria-labelledby="catalogueTable" size="medium" stickyHeader>
					<TableHeader
						columns={columns}
						totalItemCount={totalItemCount}
						checked={checked}
						onSelectAllClick={handleSelectAllClick}
					/>
					<TableBody>
						{rows.map((row: any) => {
							const isChecked = checked.indexOf(row.id) !== -1

							if (row.subItems?.length > 0) {
								return <TableCollapse handleSelect={handleSelect} key={row.id} item={row} checked={checked} />
							}

							return (
								<TableRow
									hover={true}
									aria-checked={isChecked}
									selected={isChecked}
									onClick={handleSelect(row.id)}
									key={row.id}
									tabIndex={-1}
								>
									<TableCell padding="checkbox">
										<Checkbox checked={isChecked} inputProps={{ "aria-labelledby": `checkbox-table-label-${row.id}` }} />
									</TableCell>
									{columns.map((column) => {
										const value = column.render ? column.render(row) : (row[column.id as keyof typeof row] as string)
										return (
											<TableCell component="th" scope="row" size="small" key={`catalogue-table-cell-${row.id}-${column.id}`}>
												{value}
											</TableCell>
										)
									})}
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
			{rows.length === 0 && (
				<Stack padding="6rem">
					<NoResults searchTerm={searchTerm} />
				</Stack>
			)}
		</Stack>
	)
}

export default CatalogueTable
