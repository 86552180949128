import Box from "@mui/material/Box"
import React from "react"

type TabPanelProps = React.PropsWithChildren<{
	index: number
	value: number
}>

const TabPanel = (props: TabPanelProps) => {
	const { children, value, index, ...other } = props

	return (
		<Box
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <Box>{children}</Box>}
		</Box>
	)
}

export const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	}
}

export default TabPanel
