import { getDurationText } from "@/utils"
import Typography from "@mui/material/Typography"
import React from "react"

/**
 * Render duration for Catalogue Table
 */
export const RenderDuration = ({ duration }: { duration: number }) => (
	<Typography variant="body2">{getDurationText(duration)}</Typography>
)
