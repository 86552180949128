import Typography from "@mui/material/Typography"
import ErrorModal from "."

type Props = {
	handleRetry?: () => void
	elevation?: number
}
export default function (props: Props) {
	return (
		<ErrorModal
			header="Server Error"
			description={
				<Typography>
					We couldn't reach the iKhokha server. Please try again or contact ikhokha Support Team on <strong>087 222 7000</strong>{" "}
					or email us at <strong>support@ikhokha.com</strong>
				</Typography>
			}
			handlerText="Retry"
			handleRetry={props.handleRetry}
			elevation={props.elevation}
		/>
	)
}
