import TextField from "@mui/material/TextField"
import { MagnifyingGlass, X as ClearIcon } from "@phosphor-icons/react"
import React from "react"

type TextFieldProps = {
	placeholder: string
	valueState: [string, React.Dispatch<React.SetStateAction<string>>]
}

export const SearchTextField = ({ placeholder, valueState: [searchText, setSearchText] }: TextFieldProps) => {
	return (
		<TextField
			fullWidth
			placeholder={placeholder}
			variant="filled"
			value={searchText}
			onChange={({ target: { value } }) => setSearchText(value)}
			sx={{
				input: {
					padding: ".75rem",
				},
				padding: ".5rem 1.5rem",
				width: "13.75rem",
			}}
			InputProps={{
				startAdornment: <MagnifyingGlass height="2rem" width="2rem" />,
				endAdornment: (
					<ClearIcon
						style={{ cursor: "pointer", visibility: searchText !== "" ? "visible" : "hidden" }}
						width="2rem"
						height="2rem"
						onClick={() => setSearchText("")}
					/>
				),
			}}
		/>
	)
}
