import { TableRows } from "@/components/catalogue/table/CatalogueTable"
import { Service } from "@/types"

/**
 * Creates table rows, from services, with the correct fields.
 * @param services
 * @returns
 */
export const createTableRows = (services: Service[]): TableRows => {
	return services.map((service) => ({
		id: service.catalogueItemID,
		name: service.name,
		category: service.categories[0],
		duration: service.duration,
		price: service.price,
		imagePath: service.imageList.thumbnail?.[0],
	}))
}
