import FetchCatalogue from "@/components/catalogue/FetchCatalogue"
import { createTableRows } from "@/components/catalogue/ServicesList/createTableRows"
import { servicesColumns } from "@/components/catalogue/ServicesList/tableColumns"
import CatalogueTable from "@/components/catalogue/table/CatalogueTable"
import { SearchTextField } from "@/components/catalogue/table/SearchTextField"
import { useServices } from "@/hooks"
import { itemSelectionCountAtom, servicesCheckedAtom, servicesSearchTermAtom } from "@/state"
import { getFilteredServices, sortByCreatedAtDesc } from "@/utils/service"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import { useAtom, useAtomValue } from "jotai"
import React from "react"

type ServicesListProps = {
	createCatalogueItemNavigateComp: React.ReactNode
	noRowsComp: React.ReactNode
	addToStoreComp: React.ReactNode
}
const ServicesList = (props: ServicesListProps) => {
	const { services, isLoading, isError, retry } = useServices()
	const filteredServices = getFilteredServices(services, [sortByCreatedAtDesc])

	const [searchTerm, setSearchTerm] = useAtom(servicesSearchTermAtom)
	const [servicesChecked, setServicesChecked] = useAtom(servicesCheckedAtom)
	const itemSelectionCount = useAtomValue(itemSelectionCountAtom)

	const searchedServices =
		searchTerm === ""
			? filteredServices
			: filteredServices.filter(({ name, categories, price }) =>
					(name + categories[0] + price).toLowerCase().includes(searchTerm.toLowerCase()),
				)

	const rows = React.useMemo(() => createTableRows(searchedServices), [searchedServices])

	return (
		<FetchCatalogue isLoading={isLoading} isError={isError} retry={retry}>
			{filteredServices.length === 0 ? (
				props.noRowsComp
			) : (
				<>
					<Stack direction="row" justifyContent="space-between" alignItems="center">
						{props.createCatalogueItemNavigateComp}
						<SearchTextField placeholder="Search" valueState={[searchTerm, setSearchTerm]} />
					</Stack>
					<CatalogueTable
						columns={servicesColumns}
						rows={rows}
						totalItemCount={filteredServices.length}
						checked={servicesChecked}
						setChecked={setServicesChecked}
						searchTerm={searchTerm}
					/>
					<Stack direction="row-reverse" justifyContent="space-between" padding="0 1rem">
						<Typography variant="body2">
							Total services:{" "}
							<Typography component="span" variant="subtitle2">
								{filteredServices.length}
							</Typography>
						</Typography>
						{!!itemSelectionCount && (
							<Typography variant="body2">
								<Typography component="span" variant="subtitle2">
									{itemSelectionCount}
								</Typography>{" "}
								items selected
							</Typography>
						)}
					</Stack>
				</>
			)}
			{props.addToStoreComp}
		</FetchCatalogue>
	)
}

export default ServicesList
