import NoResultsIcon from "@/assets/NoResults"
import { TransparentDivider } from "@/components/TransparentDivider"
import Stack from "@mui/material/Stack"
import { styled } from "@mui/material/styles"
import Typography from "@mui/material/Typography"

const StyledTypography = styled(Typography)(({ theme }) => ({
	color: theme.palette.text.secondary,
}))

const NoResults = ({ searchTerm }: { searchTerm: string }) => (
	<Stack justifyContent="center" alignItems="center">
		<NoResultsIcon />

		<TransparentDivider sx={{ height: "1rem" }} />

		<StyledTypography>{`We couldn't find any matches for "${searchTerm}"`}</StyledTypography>
		<StyledTypography>Please try another keyword</StyledTypography>
	</Stack>
)

export default NoResults
