import IconButton from "@/components/catalogue/IconButton"
import { button } from "@ikhokha/commons-ui/build/dist/cjs/typography"
import Typography from "@mui/material/Typography"
import { Plus } from "@phosphor-icons/react"
import React from "react"

type CreateCatalogueRedirectProps = {
	onNavigate: () => void
	catalogue: "product" | "service"
}
const CreateCatalogueRedirect = ({ onNavigate, catalogue }: CreateCatalogueRedirectProps) => (
	<IconButton onClick={onNavigate}>
		<Plus weight="bold" color="teal" />
		<Typography marginTop=".25rem" color="teal" sx={{ ...button.large }}>
			{`Add a ${catalogue}`}
		</Typography>
	</IconButton>
)

export default React.memo(CreateCatalogueRedirect)
