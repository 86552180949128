import { styled } from "@mui/material/styles"
import MUITableRow from "@mui/material/TableRow"

const TableRow = styled(MUITableRow)(({ theme }) => ({
	"&.Mui-selected.MuiTableRow-hover": {
		backgroundColor: theme.palette.grey[50],
	},
	cursor: "pointer",
}))

export default TableRow
