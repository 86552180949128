import CatalogueItemThumbnail from "@/components/CatalogueItemThumbnail"
import { getCloudfrontImageUrl } from "@/utils"
import Box from "@mui/material/Box"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"

type CatalogueGridThumbnailProps = {
	name: string
	selected: boolean
	variantsCount?: number
	imagePath?: string
}

/**
 * Render thumbnail for Catalogue Table
 */
export const RenderThumbnail = ({ name, selected, variantsCount, imagePath }: CatalogueGridThumbnailProps) => (
	<Stack p={0} m={0} direction="row" alignItems="center" gap={1}>
		<CatalogueItemThumbnail imageUrl={getCloudfrontImageUrl(imagePath)} name={name} />
		<Stack direction="column" gap={0}>
			<Typography variant="body2">{name}</Typography>
			<Typography variant="body2" color="text.secondary">
				{variantsCount && `${variantsCount} variant${variantsCount > 1 ? "s" : ""} `}
				{selected && (
					<Box component="span" color="teal">
						(selected)
					</Box>
				)}
			</Typography>
		</Stack>
	</Stack>
)
