import { styled } from "@mui/material"
import MUICheckbox from "@mui/material/Checkbox"

const CheckBox = styled(MUICheckbox)(() => ({
	"&.Mui-checked": {
		color: "teal",
	},
	"&.MuiCheckbox-indeterminate": {
		color: "teal",
	},
}))

export default CheckBox
